import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CategoryResponse } from 'src/app/components/models/product_categories';

@Injectable({
  providedIn: 'root'
})
export class ApisCategoriesService {
  BaseUrl ='http://api.adam.fashion.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  
  public getAllCategories():  Observable<CategoryResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<CategoryResponse>(this.BaseUrl+ 'admin/admin_general/category/get' ,{headers});
  }
  public deleteCategories(body):  Observable<CategoryResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<CategoryResponse>(this.BaseUrl+ 'admin/admin_general/category/delete' ,body,{headers});
  }

  public editeCategories(body ):  Observable<CategoryResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<CategoryResponse>(this.BaseUrl+ 'admin/admin_general/category/edit' ,body,{headers});
  }
  public addCategories(body):  Observable<CategoryResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.post<CategoryResponse>(this.BaseUrl+ 'admin/admin_general/category/add' ,body,{headers});
  }

}
