import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeResponse } from 'src/app/components/models/home';

@Injectable({
  providedIn: 'root'
})
export class ApisHomeServicesService {

  BaseUrl ='http://api.adam.fashion.apishipping.iraq-soft.info/'
  token :string;
  lang :string;
  constructor(private http: HttpClient) { }

  
  public getHome():  Observable<HomeResponse>{
    this.token = localStorage.getItem("token")
    this.lang = localStorage.getItem("lang") ??"ar";
    let headers: HttpHeaders = new HttpHeaders()
    .set("lang" , this.lang)
    .set("Authorization","Bearer " + this.token);
    return this.http.get<HomeResponse>(this.BaseUrl+ 'admin/general/dashboard-home' ,{headers});
  }
}
